import React from "react";
import { Button, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

export const ModalHeader = ({
  title,
  icon,
  onClose,
}: {
  onClose: () => void;
  title: JSX.Element | string;
  icon?: JSX.Element;
}): React.JSX.Element => {
  return (
    <Modal.Header>
      <Modal.Title className='w-100 d-flex' style={{ height: "fit-content" }}>
        {typeof title === "string" ? (
          <>
            {icon}
            <h2 className='m-auto p-2' style={{ textAlign: "center" }}>
              {title}
            </h2>
          </>
        ) : (
          title
        )}
        <Button
          className='ml-auto mr-0'
          style={{
            width: "fit-content",
            height: "fit-content",
          }}
          onClick={onClose}
        >
          <AiOutlineClose />
        </Button>
      </Modal.Title>
    </Modal.Header>
  );
};
