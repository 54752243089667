import { useCallback, useContext } from "react";
import { AuthContext } from "../../App";
import { useQuery } from "@tanstack/react-query";

type UseAuthHook = { authenticated: boolean };

export const useAuth = (): UseAuthHook => {
  const auth = useContext(AuthContext);

  const authenticationCheck = useCallback(
    async () =>
      await fetch("/api/authenticate", {
        method: "POST",
        body: JSON.stringify({ password: "authCheck" }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        auth.setAuthenticated?.(res.ok);
        //react-query can't handle undefined without throwing a warning ...
        return null;
      }),
    [auth]
  );

  useQuery({
    queryFn: authenticationCheck,
    queryKey: ["auth"],
  });

  return auth;
};
