export const bookShelfs = {
  available: "AVAILABLE",
  fnord1: "FNORD1",
  fnord2: "FNORD2",
  sharing: "SHARING",
} as const;

export type Shelf = (typeof bookShelfs)[keyof typeof bookShelfs];

export type Book = {
  id: number;
  uuid: string;
  isbn: string;
  title: string;
  published: number;
  lastCheckoutDate: number | null;
  checkoutBy: string | null;
  contact: string | null;
  shelf: Shelf | null;
};
