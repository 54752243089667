import React, { useContext } from "react";
import { Book as BookType } from "../../types/Book";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ImSpinner2 } from "react-icons/im";
import { Actions } from "../Library/components/Actions";
import { ModalContext } from "../../App";
import { ModalSelector } from "../../shared/components/modals/Modals";
import { useAuth } from "../../shared/utils/useAuthentication";

export const Book = (args?: { book?: BookType }): React.JSX.Element => {
  const params = useParams<{ uuid: string }>();

  const auth = useAuth();
  const modalContext = useContext(ModalContext);

  const {
    data: fetchedBook,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<BookType>({
    queryKey: ["book", params.uuid],
    queryFn: async () => {
      const response = await fetch(`/api/books/find`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uuid: params.uuid }),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return (await response.json())[0];
    },
  });

  const book = args?.book ?? fetchedBook;

  return (
    <div className='m-auto w-50 d-flex flex-column'>
      <ModalSelector {...modalContext} />
      {isLoading || isFetching ? (
        <ImSpinner2 className='spin m-auto' size={50} />
      ) : !!error ? (
        <h2 className='m-auto'>{error.message}</h2>
      ) : !book ? (
        <h2 className='m-auto'>404 Book not found</h2>
      ) : (
        <>
          <h1>{book.title}</h1>
          <Row>
            <Col>ISBN</Col>
            <Col>{book.isbn}</Col>
          </Row>
          <Row>
            <Col>Shelf</Col>
            <Col>{book.shelf}</Col>
          </Row>
          <Row>
            <Col>Actions</Col>
            <Col>
              <Actions
                book={book}
                refetch={refetch}
                authenticated={auth.authenticated}
                setActiveModal={modalContext.setActiveModal}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
