import React, { useCallback, useContext, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Book } from "../../types/Book";
import { Pagination, Table } from "react-bootstrap";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable,
  ColumnDef,
} from "@tanstack/react-table";
import { Actions } from "./components/Actions";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { useAuth } from "../../shared/utils/useAuthentication";
import { ModalSelector } from "../../shared/components/modals/Modals";
import { ModalContext } from "../../App";

export const Library = (): React.JSX.Element => {
  const location = useLocation();
  const locationState = location.state;

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );

  const auth = useAuth();
  const modalContext = useContext(ModalContext);

  const { data: books, refetch } = useQuery<Book[]>({
    queryFn: async () => {
      if (
        locationState &&
        "found" in locationState &&
        !!locationState.found.length
      ) {
        return locationState.found;
      }
      const response = await fetch(`/api/books/list`);
      const data = await response.json();
      return data;
    },
    queryKey: ["books"],
  });

  const columnHelper = createColumnHelper<Book>();
  const columns: ColumnDef<Book, any>[] = useMemo(
    () =>
      [
        columnHelper.accessor((book) => book.isbn, {
          id: "isbn",
          header: "ISBN",
          size: 50,
          cell: (props) => <div>{props.row.original.isbn}</div>,
        }),
        columnHelper.accessor((book) => book.title, {
          id: "title",
          header: "Title",
          size: 200,
          cell: (props) => <div>{props.row.original.title}</div>,
        }),
        columnHelper.accessor((book) => book.published, {
          id: "published",
          header: "Year published",
          size: 30,
          cell: (props) => <div>{props.row.original.published}</div>,
        }),
        columnHelper.accessor((book) => book.shelf, {
          id: "shelf",
          header: "Shelf",
          size: 50,
          cell: (props) => <div>{props.row.original.shelf}</div>,
        }),
        columnHelper.accessor(
          (book) => (!book.checkoutBy ? "" : book.checkoutBy),
          {
            id: "checkoutBy",
            size: 50,
            header: "Checked out by",
            cell: (props) => (
              <div>
                {!props.row.original.checkoutBy
                  ? ""
                  : props.row.original.checkoutBy}
              </div>
            ),
          }
        ),
        columnHelper.accessor((book) => book.lastCheckoutDate, {
          id: "lastCheckoutDate",
          header: "Last checkout",
          size: 50,
          cell: (props) => (
            <div>
              {!props.row.original.lastCheckoutDate
                ? ""
                : format(
                    Number(props.row.original.lastCheckoutDate),
                    "yyyy MM dd"
                  )}
            </div>
          ),
        }),
        auth.authenticated
          ? columnHelper.accessor(
              (book) => (!book.contact ? "" : book.contact),
              {
                id: "contactInfo",
                header: "Contact info",
                size: 50,
                cell: (props) => (
                  <div>
                    {!props.row.original.contact
                      ? ""
                      : props.row.original.contact}
                  </div>
                ),
              }
            )
          : undefined,
        columnHelper.display({
          id: "actions",
          header: "Actions",
          size: 30,
          cell: (props) => (
            <Actions
              book={props.row.original}
              refetch={refetch}
              authenticated={auth.authenticated}
              setActiveModal={modalContext.setActiveModal}
            />
          ),
        }),
      ].filter((c) => typeof c !== "undefined") as ColumnDef<Book, any>[],
    [auth.authenticated, columnHelper, modalContext.setActiveModal, refetch]
  );

  const shelf = params.get("shelf");
  const year = params.get("year");

  const data = useMemo(
    () =>
      books?.filter(
        (b) =>
          (!shelf || b.shelf?.toLowerCase() === shelf?.toLowerCase()) &&
          (!year || b.published.toString() === year)
      ) ?? [],
    [books, year, shelf]
  );

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  const currentPage = useMemo(
    () => table.getState().pagination.pageIndex,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.getState().pagination.pageIndex]
  );

  const getPage = useCallback(
    (n: number) => (
      <Pagination.Item
        key={`${n}th-page`}
        active={n === currentPage}
        onClick={() => table.setPageIndex(n)}
      >
        {n + 1}
      </Pagination.Item>
    ),
    [table, currentPage]
  );

  return (
    <div className='m-auto p-2' style={{ width: "100%", maxHeight: "100vh" }}>
      <ModalSelector {...modalContext} />
      <h1>Library</h1>
      <div
        style={{
          maxWidth: "100%",
          maxHeight: "70vh",
          overflow: "auto",
          marginBottom: "5px",
        }}
      >
        <Table striped bordered hover>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} colSpan={header.colSpan}>
                      <div
                        style={{ cursor: "pointer" }}
                        {...{
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {!!header.column.getIsSorted() &&
                          ((header.column.getIsSorted() as string) === "asc" ? (
                            <ImArrowUp />
                          ) : (
                            <ImArrowDown />
                          ))}
                      </div>
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {table.getPageCount() ? (
        <div className='d-flex w-100'>
          <Pagination className='d-flex m-auto'>
            <Pagination.Item
              key='prev'
              disabled={currentPage === 0}
              onClick={() => table.setPageIndex(currentPage - 1)}
            >
              <AiOutlineLeft />
            </Pagination.Item>
            {getPage(0)}
            {currentPage > 3 && <Pagination.Ellipsis />}
            {currentPage === 3 && getPage(currentPage - 2)}
            {currentPage > 1 && getPage(currentPage - 1)}
            {currentPage > 0 && getPage(currentPage)}
            {currentPage < table.getPageCount() - 2 && getPage(currentPage + 1)}
            {currentPage < table.getPageCount() - 3 &&
              table.getPageCount() > 4 && <Pagination.Ellipsis />}
            {currentPage < table.getPageCount() - 1 &&
              getPage(table.getPageCount() - 1)}
            <Pagination.Item
              key='next'
              onClick={() => table.setPageIndex(currentPage + 1)}
              disabled={currentPage >= table.getPageCount() - 1}
            >
              <AiOutlineRight />
            </Pagination.Item>
          </Pagination>
        </div>
      ) : null}
    </div>
  );
};
