import React, { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Button, Form } from "react-bootstrap";
import {
  ImBook,
  ImBooks,
  ImCamera,
  ImSearch,
  ImSpinner2,
} from "react-icons/im";

import { TfiKey } from "react-icons/tfi";

import { Link, useNavigate } from "react-router-dom";
import { Book } from "../../types/Book";
import { useAuth } from "../../shared/utils/useAuthentication";
import { ModalContext } from "../../App";
import { modalTypes } from "../../shared/components/modals/types";
import { ModalSelector } from "../../shared/components/modals/Modals";

const { auth, scanner, book } = modalTypes;

export const Main = (): React.JSX.Element => {
  const { authenticated } = useAuth();
  const { activeModal, setActiveModal } = useContext(ModalContext);

  const [title, setTitle] = useState<string | undefined>(undefined);
  const [isbn, setIsbn] = useState<string | undefined>(undefined);

  const [error, setError] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  const { refetch, isFetching } = useQuery<Book | null>({
    queryKey: ["bookTitle", isbn],
    queryFn: async () => {
      if (!title && !isbn) {
        return null;
      }

      const response = await fetch("/api/books/find", {
        method: "POST",
        body: JSON.stringify({ title, isbn }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setError(await response.text());
        return null;
      } else {
        const books = await response.json();

        if (!books.length) {
          setError("No books found.");
          return null;
        }
        if (books.length === 1) {
          navigate(`/book/${books[0].uuid}`);
        } else {
          navigate(`/books`, {
            state: {
              found: books,
            },
          });
        }
      }

      return (await response.json())[0];
    },
  });

  return (
    <div className='d-flex flex-column w-100'>
      <ModalSelector
        activeModal={activeModal}
        setActiveModal={setActiveModal}
      />
      <h1 className='m-auto mb-2'>Librarian</h1>
      <Form
        className='w-100 mb-0 mt-0 mx-auto'
        style={{ maxWidth: "500px" }}
        onSubmit={(ev) => {
          ev.preventDefault();
          refetch();
        }}
      >
        <div className='d-flex'>
          <Form.Control
            value={title}
            disabled={isFetching}
            placeholder='Search for a title or use the camera to scan a barcode . . .'
            onChange={(ev) => setTitle(ev.target.value)}
            style={{
              borderRadius: "20px 0px 0px 20px",
              borderRight: "none",
            }}
          />
          <Button
            style={{
              borderRadius: "0px 5px 5px 0px",
            }}
            className='mr-2'
            disabled={activeModal?.type === scanner || isFetching}
            onClick={() =>
              !!title
                ? refetch()
                : setActiveModal({ type: scanner, onDetect: setIsbn })
            }
          >
            {isFetching ? (
              <ImSpinner2 className='spin' />
            ) : !!title ? (
              <ImSearch />
            ) : (
              <ImCamera size={20} />
            )}
          </Button>
        </div>
        <Form.Control.Feedback
          style={{ display: !!error ? "block" : "none" }}
          type='invalid'
        >
          {error}
        </Form.Control.Feedback>
      </Form>
      <div className='d-flex mx-auto mb-auto mt-2 w-100'>
        {authenticated ? (
          <Button
            style={{
              borderRadius: "5px",
              marginLeft: "auto",
              marginRight: "10px",
            }}
            onClick={() =>
              setActiveModal({ type: book, onClose: setActiveModal })
            }
          >
            <ImBook /> Add new Book
          </Button>
        ) : (
          <Button
            style={{
              borderRadius: "5px",
              marginLeft: "auto",
              marginRight: "10px",
            }}
            onClick={() => setActiveModal({ type: auth })}
          >
            <TfiKey /> Admin Login
          </Button>
        )}
        <Link
          to='/books'
          state={{ found: [] }}
          style={
            authenticated
              ? { marginRight: "10px", marginLeft: "10px" }
              : { marginRight: "auto", marginLeft: "10px" }
          }
        >
          <Button
            style={{
              borderRadius: "5px",
            }}
          >
            <ImBooks /> Browse library{" "}
          </Button>
        </Link>
        {authenticated && (
          <Button
            style={{
              borderRadius: "5px",
              marginRight: "auto",
              marginLeft: "10px",
            }}
            onClick={() => setActiveModal({ type: auth, isUpdate: true })}
          >
            <TfiKey /> Set new Admin key{" "}
          </Button>
        )}
      </div>
    </div>
  );
};
